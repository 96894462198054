import React from "react";
import Navbar from "../Home/navbar";
import "../GetinTouch/index.css"

const Introduction = () => {
    return (
        <div className="relative h-[70rem] md:h-[110vh]">
            <video
                autoPlay
                loop
                muted
                className="w-full h-[90rem] md:h-full object-cover"
            >
                <source src="/assets/getintouch.mp4" type="video/mp4"/>
            </video>
            {/* Content to be overlaid */}
            <div className="absolute top-0 left-0 w-full h-[90rem] md:h-full bg-multiple-gradients">
                {/* <div className="text-center mt-1/2" style={{ position: 'absolute', top: '25%', transform: 'translateY(-50%)', left: '50%', transform: 'translateX(-50%)' }}>
                    <h1 className="flex justify-center items-center font-extrabold text-8xl text-white mr-[15rem]">Collaborate</h1>
                    <h1 className="flex justify-center items-center font-extrabold text-8xl text-white ml-[19rem]">With Us</h1>
                    <div className="flex justify-center items-center ml-[19rem]">
                        <img src="assets/marketinglineelement.svg" />
                    </div>
                </div> */}
                <div className="grid md:grid-cols-2 flex-wrap px-[2.5vw] md:px-[3rem] py-[5rem] gap-y-8">
                    <div className="w-full md:w-[80%] mx-auto text-[#FFFFFF] font-extrabold text-3xl">
                        <h1 className="">We are <span className="text-[#F25878]">Innovators</span>,</h1>
                        <h1>Crafting your <span className="text-[#00DBFF]">Tomorrow</span></h1>
                        <p className="font-normal text-lg my-5">
                        Welcome to Instinkt , where unorthodox creativity meets the future.. We're not your ordinary marketing agency; we're a rebellious collective of rule-breakers, pushing the boundaries of what's possible. Our team is a colourful concoction of passionate minds, fueled by caffeine and crazy ideas, always ready to disrupt the market. 
                        </p>
                        <p className="text-lg font-normal">
                            We believe in the power of thinking differently and harnessing technology to revolutionize brand experiences. So, whether you're a tech-savvy startup or an established brand seeking a bold transformation, buckle up and join us on this wild ride. 
                        </p>
                    </div>
                    <div className="w-full md:w-[80%] mx-auto text-[#FFFFFF] font-extrabold text-3xl">
                        {/* <h1 className="">We are <span className="text-[#F25878]">Innovators</span>,</h1>
                        <h1>Crafting your <span className="text-[#00DBFF]">Tomorrow</span></h1>
                        <p className="text-lg font-normal">
                        Welcome to Instinkt , where unorthodox creativity meets the future.. We're not your ordinary marketing agency; we're a rebellious collective of rule-breakers, pushing the boundaries of what's possible. Our team is a colourful concoction of passionate minds, fueled by caffeine and crazy ideas, always ready to disrupt the market.
                        </p>
                        <p className="text-lg font-normal">
                            We believe in the power of thinking differently and harnessing technology to revolutionize brand experiences. So, whether you're a tech-savvy startup or an established brand seeking a bold transformation, buckle up and join us on this wild ride.
                        </p> */}
                        <div id='contact-form' className="w-full md:w-[80%] mx-auto">
                            <h1 className="text-center mb-4">We're just a <span className="text-[#00DBFF]">Message Away!</span></h1>
                            <section className="bg-white bg-opacity-10 rounded-[3rem] backdrop-blur-2xl shadow p-8">
                                <div className="mx-auto w-full mt-2">
                                    <form action="https://formsubmit.co/parshva.shah.398@gmail.com" className="space-y-4 " method="POST">
                                        <div>
                                            <label htmlFor="name" className="block mb-2 text-xl font-bold">NAME</label>
                                            <input name="Name" type="text" id="name" className="shadow-sm bg-white border border-opacity-50 border-gray-100 font-medium text-sm rounded-full block w-full p-2.5 bg-opacity-0" placeholder="What should we call you?" required/>
                                        </div>
                                        <div>
                                            <label htmlFor="organisation" className="block mb-2 text-xl font-bold">ORGANISATION</label>
                                            <input name="Organisation" type="text" id="organisation" className="shadow-sm bg-white border border-opacity-50 border-gray-100 font-medium text-sm rounded-full block w-full p-2.5 bg-opacity-0" placeholder="Wo are we addressing?" required/>
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block mb-2 text-xl font-bold">EMAIL ID</label>
                                            <input name="Email" type="email" id="email" className="shadow-sm bg-white border border-opacity-50 border-gray-100 font-medium text-sm rounded-full block w-full p-2.5 bg-opacity-0" placeholder="you@arethebest.com" required/>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="message" className="block mb-2 text-xl font-bold">TELL US YOUR GOALS</label>
                                            <textarea name="Message" id="message" rows="6" className="shadow-sm bg-white border border-opacity-50 border-gray-100 font-medium text-sm rounded-3xl block w-full p-2.5 bg-opacity-0" placeholder="Your thoughts, Our action..."></textarea>
                                        </div>
                                        <button type="submit" className="w-full text-center mx-auto py-2 text-lg font-bold text-center rounded-full text-pracula bg-[#6c63ff] hover:bg-white hover:text-black duration-500">GAME ON!</button>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction;