import React from "react";
import workout_image from "../../assets/workout-buddy.webp";
import health_image from "../../assets/health-check-ai.webp";
import seeker_image from "../../assets/home-seeker-ai.webp";
import car_image from "../../assets/car-guide-ai.webp";

const Chatbotassistant = () => {
    return (
        <div id="aichatbots" className="bg-[#202427]">
          <div className="max-w-7xl mx-auto py-32">
            <div className="text-white text-5xl lg:text-[8rem] text-center font-black leading-tight pb-32">
              <h1>AI Chatbots</h1>
              <h1>& Assistants</h1>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${workout_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-white px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  Workout <span className="text-cyan-400">Buddy.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  YOUR PERSONAL FITNESS GURU
                </p>
                <hr />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  With our AI Workout Buddy, offer personalized fitness plans for
                  your members, considering their goals, preferences, and health
                  conditions. Keep them engaged, motivated, and successful – the
                  secret to retaining satisfied, healthier clients.
                </p>
              </div>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${health_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-white px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  Health Check <span className="text-yellow-500">AI.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  YOUR INSTANT HEALTH COMPANION
                </p>
                <hr />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  Enhance healthcare with Health Check AI, offering quick, precise
                  health assessments and guidance. Improve patient care and
                  streamline diagnoses, leading to better outcomes and efficient
                  workflows. It's healthcare at its smartest.
                </p>
              </div>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${seeker_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-white px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  Home <span className="text-purple-500">Seeker.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  FIND YOUR DREAM HOME EFFORTLESSLY
                </p>
                <hr />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  Maximize efficiency with our AI Home Seeker. Input client
                  preferences, and watch it find their perfect home match. No more
                  mismatched showings, just quicker deals and happy homeowners.
                </p>
              </div>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${car_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-white px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  Car Guide <span className="text-red-400">AI.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  YOUR AUTOMATED SALES REP
                </p>
                <hr />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  In the auto industry, personalization sets you apart. Car Guide
                  AI matches buyers with their ideal ride, creating lasting
                  relationships, ensuring style and loyalty. The future of car
                  sales.
                </p>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Chatbotassistant;