import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  HashRouter
} from "react-router-dom";
import Home from "./pages/home";
import Automate from "./pages/automate";
import Creative from "./pages/creative";
import Marketing from "./pages/marketing";
import GetInTouch from "./pages/getintouch";
import Error from "./pages/errorpage";
import Outsource from "./pages/outsource";
import ComingSoon from "./pages/comingsoon";
import Graphicdesign from "./components/Creative/Graphicdesign";

function App() {
  return (
    <div className="App font-display">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/automate" element={<Automate />} />
          <Route exact path="/creative" element={<Creative />} />
          <Route exact path="/marketing" element={<Marketing />} />
          <Route exact path="/getintouch" element={<GetInTouch />} />
          <Route exact path="/outsource" element={<Outsource />} />
          <Route exact path="/blog" element={<ComingSoon />} />
          <Route exact path="/*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
