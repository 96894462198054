import React, {useEffect, useState} from "react";
import Introduction from "../components/Outsource/Introduction";
import Navbar from "../components/Home/navbar";
import Benefits from "../components/Outsource/benefits";
import Recieve from "../components/Outsource/recieve";
import Footer from "../components/Home/footer";
import loadinglogo from "../assets/loading.webp";
import SyncLoader from "react-spinners/SyncLoader";

const Outsource = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    return (
        <div>
            {loading ? <div className="h-screen w-screen bg-black flex flex-col justify-center items-center">
                <img src={loadinglogo} alt="loading"/>
                <SyncLoader
                    size={20}
                    color={"#fff"}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div> :
            <div>
                <Navbar/>
                <Introduction/>
                <Benefits/>
                <Recieve/>
                <Footer/>
            </div>
            }
        </div>
    )
}

export default Outsource;