import React from "react";
import logos from "../../assets/logos.webp";
import social_media from "../../assets/social_media.webp";
import branding from "../../assets/branding.webp";
import ui_ux from "../../assets/ui_ux.webp";

const Graphicdesign = () => {
  return (
    <div id="graphic-design" className="bg-[#1D1F21]">
      <div className="max-w-7xl mx-auto py-32">
        <div className="text-[#F7F4EC] text-5xl lg:text-[8rem] text-center font-black leading-tight pb-32">
          <h1>GRAPHIC</h1>
          <h1>DESIGN</h1>
        </div>
        <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
          <div className="p-8 lg:pr-8 lg:m-auto">
            <div
              style={{
                "--image-url": `url(${logos})`,
                backgroundSize: "120%",
                backgroundPositionY: "-30px",
              }}
              className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
            ></div>
          </div>
          <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-[#F7F4EC] px-8 lg:pl-8 lg:m-auto mx-auto">
            <p className="text-5xl lg:text-7xl font-[800]">
              Log<span className="text-cyan-400">os.</span>
            </p>
            <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
              UNFORGETTABLE SYMBOL OF IDENTITY
            </p>
            <hr />
            <p className="mt-8 lg:pr-28 lg:text-lg">
              Crafting unforgettable brand logos that leave a lasting impression
              in a crowded market – our logo design makes your brand instantly
              recognizable.
            </p>
          </div>
        </div>
        <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
          <div className="p-8 lg:pr-8 lg:m-auto">
            <div
              style={{
                "--image-url": `url(${social_media})`,
                backgroundSize: "100%",
              }}
              className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
            ></div>
          </div>
          <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-[#F7F4EC] px-8 lg:pl-8 lg:m-auto mx-auto">
            <p className="text-5xl lg:text-7xl font-[800]">
              Social Media <span className="text-red-400">Posts.</span>
            </p>
            <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
              STAND OUT IN THE ENDLESS SCROLL WITH THUMB-STOPPING VISUALS
            </p>
            <hr />
            <p className="mt-8 lg:pr-28 lg:text-lg">
              Launch software like Photoshop and open the downloaded mockup
              file. Locate the layers specifically marked for customization.
            </p>
          </div>
        </div>
        <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
          <div className="p-8 lg:pr-8 lg:m-auto">
            <div
              style={{ "--image-url": `url(${ui_ux})`, backgroundSize: "100%" }}
              className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
            ></div>
          </div>
          <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-[#F7F4EC] px-8 lg:pl-8 lg:m-auto mx-auto">
            <p className="text-5xl lg:text-7xl font-[800]">
              UI/UX <span className="text-purple-500">Design.</span>
            </p>
            <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
              HERE'S THE SCOOP: 88% OF USERS ARE ON THE HUNT FOR A PERSONALIZED
              EXPERIENCE
            </p>
            <hr />
            <p className="mt-8 lg:pr-28 lg:text-lg">
              Insert your app or website screenshot by dragging and dropping it
              into the designated customization layer. Then save and export your
              finished mockup.
            </p>
          </div>
        </div>
        <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
          <div className="p-8 lg:pr-8 lg:m-auto">
            <div
              style={{
                "--image-url": `url(${branding})`,
                backgroundSize: "100%",
              }}
              className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
            ></div>
          </div>
          <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-[#F7F4EC] px-8 lg:pl-8 lg:m-auto mx-auto">
            <p className="text-5xl lg:text-7xl font-[800]">
              Bran<span className="text-yellow-500">ding.</span>
            </p>
            <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
              FROM HANDOUTS TO PRODUCT PACKAGING, WE BRAND IT ALL.
            </p>
            <hr />
            <p className="mt-8 lg:pr-28 lg:text-lg">
              Insert your app or website screenshot by dragging and dropping it
              into the designated customization layer. Then save and export your
              finished mockup.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graphicdesign;
