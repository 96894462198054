import React, {useEffect, useState} from "react";
import Navbar from "../components/Home/navbar";
import Snowman from "../assets/Snowman.webp";
import loadinglogo from "../assets/loading.webp";
import SyncLoader from "react-spinners/SyncLoader";

const ComingSoon = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    return (
        <div>
            {loading?<div className="h-screen w-screen bg-black flex flex-col justify-center items-center">
                <img src={loadinglogo} alt="loading"/>
                <SyncLoader
                    size={20}
                    color={"#fff"}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div> :
            <div className="h-[100vh]">
                <Navbar/>
                <div className="lg:h-full h-auto absolute top-0 -z-30">
                    <video loop autoPlay={true}>
                        <source src="/assets/comingsoon.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className="lg:h-full h-auto bg-[#1D1F21] bg-opacity-50">
                    <div className="flex justify-center pt-[10%] text-white text-[10rem] font-bold items-center">
                        <h1 className>Com</h1>
                        <img src={Snowman} className="h-[10rem]"/>
                        <h1>ng Soon</h1>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default ComingSoon;