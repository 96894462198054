import React from "react";
import Navbar from "../Home/navbar";
import { SectionWrapper } from "../../hoc";
import { fadeIn } from "../../utils/motion";
import {motion} from "framer-motion";

const Introduction = () => {
    return (
        <div className="lg:h-[100vh] h-auto relative -z-30">
            <video
                autoPlay
                loop
                muted
                className="w-auto object-cover h-[20rem] md:h-auto md:w-full"
            >
                <source src="/assets/outsource.mp4" type="video/mp4"/>
            </video>
            {/* Content to be overlaid */}
            <div className="absolute top-0 left-0 w-full h-[20rem] md:h-[100vh] bg-multiple-gradients">
                <div className="text-center mt-1/2 pt-6 md:pt-0" style={{ position: 'absolute', top: '25%', transform: 'translateY(-50%)', left: '50%', transform: 'translateX(-50%)' }}>
                    <motion.h1 
                        className="flex justify-center items-center font-extrabold text-4xl md:text-8xl text-white md:mr-[15rem]"
                        variants={fadeIn("up", "tween", 0.5,0.5)}
                    >
                            Collaborate
                    </motion.h1>
                    <motion.h1 
                        className="flex justify-center items-center font-extrabold text-4xl md:text-8xl text-white md:ml-[19rem]"
                        variants={fadeIn("up", "tween", 1,0.5)}
                    >
                        With Us
                    </motion.h1>
                    <motion.div 
                        className="flex justify-center items-center md:ml-[19rem]"
                        variants={fadeIn("up","tween",1.5,0.5)} 
                    >
                        <img src="assets/marketinglineelement.svg" />
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default SectionWrapper(Introduction);