import React from "react";
import Spline from "@splinetool/react-spline";
import {motion} from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";

const Introduction = () => {
  return (
    <div>
      <div className="max-w-full lg:h-full h-[100rem] absolute top-0 -z-30">
        <div className="md:h-screen h-[60vh] w-screen">
          <Spline
            scene="https://prod.spline.design/PFTbzGo1tzahJbsC/scene.splinecode"
            className="h-full object-cover"
          />
        </div>
      </div>
      <div className="lg:h-[100vh] bg-[#1D1F21] bg-opacity-50 py-12 mt-2 md:mt-0 md:py-0">
        <div className="max-w-7xl mx-auto lg:py-[25vh] py-6 relative top-0">
          <div className="grid grid-cols-12 px-[2.5vw] text-[#F7F4EC] lg:gap-24">
            <motion.h1 
                className="col-start-2 lg:col-start-2 text-4xl lg:text-[7rem] font-[800] col-span-full"
                variants={fadeIn("up", "tween", 0.5, 0.5)}
            >
              Time to take
            </motion.h1>
            <motion.h1 
                className="col-start-4 lg:col-start-4 text-4xl lg:text-[7rem] font-[800] col-span-full"
                variants={fadeIn("up", "tween", 0.8, 0.5)}
            >
              your flight on{" "}
            </motion.h1>
            <motion.h1 
                className="col-start-6 lg:col-start-6 text-4xl lg:text-[7rem] font-[800] col-span-full"
                variants={fadeIn("up", "tween", 1.2, 0.5)}
            >
              <span className="text-[#FFB005] italic">Auto-pilot</span>
            </motion.h1>
          </div>
          <div className=" ml-[40%] mb-[1rem] md:mb-0 md:mt-[3rem]">
            <motion.img
              src="../assets/automatelineelement.svg"
              className="mx-auto h-[10vh]"
              variants={fadeIn("up", "tween", 1.5, 0.5)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper(Introduction);
