import React from "react";
import funnel_image from "../../assets/ai-funnel-generation.webp";
import crm_image from "../../assets/crm-automation.webp";
import outreach_image from "../../assets/ai-outreach-systems.webp";

const AIinmarketing = () => {
    return (
        <div id="aiinmarketing" className="bg-stone-100">
          <div className="max-w-7xl mx-auto py-32">
            <div className="text-black text-5xl lg:text-[8rem] text-center font-black leading-tight pb-32">
              <h1>AI In</h1>
              <h1>Marketing</h1>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${funnel_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-black px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  Funnel Generation &{" "}
                  <span className="text-red-400">Automation.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  CRAFT SEAMLESS SALES FUNNELS THAT CONVERT
                </p>
                <hr className="h-px border-0 bg-gray-800" />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  Our Funnel Generation & Automation service ensures a tireless,
                  expert sales assistant. We craft dynamic pathways from initial
                  interest to final purchase, exceeding typical sales funnels.
                </p>
              </div>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${crm_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-black px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  Lead Nurturing &{" "}
                  <span className="text-purple-400"> CRM Automation.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  BUILD LASTING RELATIONSHIP WITH YOUR LEADS
                </p>
                <hr className="h-px border-0 bg-gray-800" />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  In the fast-paced business world, strong lead relationships are
                  key. Our Lead Nurturing & CRM Automation uses AI to streamline
                  lead management, ensuring no lead goes forgotten, automating
                  follow-ups, and boosting conversions.
                </p>
              </div>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${outreach_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-black px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  {" "}
                  Cold Outreach <span className="text-cyan-400"> Systems.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  MAKE COLD OUTREACH FEEL WARM
                </p>
                <hr className="h-px border-0 bg-gray-800" />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  Elevate cold outreach with a personal touch. Our AI tailors
                  messages to resonate with your audience, building trust and
                  turning cold leads into warm prospects. Stand out in the crowd.
                </p>
              </div>
            </div>
          </div>
        </div>
    )
}

export default AIinmarketing;
