import React from "react";
import { ContentCard3 } from "./contentCard";
import { WrappedContentCard3 } from "./cardWrappers";

const Media = () => {
    return (
        <div id="adsmedia" className="h-[360vh] bg-[#1D1F21] text-[#F7F4EC]">
            <h1 className="font-black text-4xl md:text-[6rem] leading-normal flex justify-center mx-auto w-[70%] text-center pt-[12rem] pb-[10rem]">MEDIA ALLOCATION & ADVERTISING</h1>
            <WrappedContentCard3 />
        </div>
    )
}

export default Media;