import React from "react";
import {motion} from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";

const TaskwithAi = () => {
    return (
        <div className="py-[12.5vh] lg:py-[25vh] bg-[#D3D3D3] w-full">
          <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-12 px-[2.5vw] text-black lg:gap-24">
            <motion.h1 
                className="ml-3 col-start-1 lg:col-start-1 text-3xl lg:text-[6rem] font-[800] col-span-full"
                variants={fadeIn("up", "tween", 0.5, 0.5)}
            >
              Say Goodbye to
            </motion.h1>
            <motion.h1 
                className="m-1 col-start-2 lg:col-start-3 text-3xl lg:text-[6rem] font-[800] col-span-full"
                variants={fadeIn("up", "tween", 0.8, 0.5)}    
            >
              manual, repetetive
            </motion.h1>
            <motion.h1 
                className="m-1 col-start-6 lg:col-start-6 text-3xl lg:text-[6rem] font-[800] col-span-full text-red-400"
                variants={fadeIn("up", "tween", 1.2, 0.5)}
            >
              tasks with AI.
            </motion.h1>
          </div>
          <div className="mt-4 md:mt-16 ml-[50%]">
            <motion.img src="../assets/orangeline.svg" variants={fadeIn("up", "tween", 1.5, 0.5)} />
          </div>
          </div>
        </div>
    )
}

export default SectionWrapper(TaskwithAi);