import React from "react";
import video_shoots from "../../assets/video_shoots.webp";
import product_shoots from "../../assets/product_shoots.webp";
import ugc_ads from "../../assets/ugc_ads.webp";

const Ads = () => {
  return (
    <div id="ugcads" className="bg-[#F7F4EC]">
      <div className="max-w-7xl mx-auto py-32">
        <div className="text-[#1D1F21] text-5xl lg:text-[8rem] text-center font-black leading-tight pb-32">
          <h1>SHOOTS &</h1>
          <h1>UGC ADS</h1>
        </div>
        <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
          <div className="p-8 lg:pr-8 lg:m-auto">
            <div
              style={{
                "--image-url": `url(${video_shoots})`,
                backgroundSize: "100%",
              }}
              className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
            ></div>
          </div>
          <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-[#1D1F21] px-8 lg:pl-8 lg:m-auto mx-auto">
            <p className="text-5xl lg:text-7xl font-[800]">
              Video<span className="text-yellow-500">shoots.</span>
            </p>
            <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
              FROM IDEAS TO ACTION, WE CREATE CINEMATIC MAGIC.
            </p>
            <hr />
            <p className="mt-8 lg:pr-28 lg:text-lg">
              Lights, camera, action! Transform your creative concepts into
              cinematic visual stories. We handle everything, from scripting to
              shooting, to make your brand's message shine on screen.
            </p>
          </div>
        </div>
        <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
          <div className="p-8 lg:pr-8 lg:m-auto">
            <div
              style={{
                "--image-url": `url(${product_shoots})`,
                backgroundSize: "100%",
              }}
              className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
            ></div>
          </div>
          <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-[#1D1F21] px-8 lg:pl-8 lg:m-auto mx-auto">
            <p className="text-5xl lg:text-7xl font-[800]">
              Product <span className="text-purple-500">Shoots.</span>
            </p>
            <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
              PICTURE-PERFECT PRODUCTS THAT TURN HEADS
            </p>
            <hr />
            <p className="mt-8 lg:pr-28 lg:text-lg">
              Our product shoots turn offerings into attention-grabbing assets.
              With high-quality photography and creative staging, we make your
              products leave a lasting impression.
            </p>
          </div>
        </div>
        <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
          <div className="p-8 lg:pr-8 lg:m-auto">
            <div
              style={{
                "--image-url": `url(${ugc_ads})`,
                backgroundSize: "100%",
              }}
              className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
            ></div>
          </div>
          <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-[#1D1F21] px-8 lg:pl-8 lg:m-auto mx-auto">
            <p className="text-5xl lg:text-7xl font-[800]">
              UGC <span className="text-cyan-500">Ads.</span>
            </p>
            <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
              CRAFTING ADS THAT DON'T FEEL LIKE ADS
            </p>
            <hr />
            <p className="mt-8 lg:pr-28 lg:text-lg">
              In a crowded ad landscape, authenticity is your edge. Our UGC
              content connects like a conversation, speaking your brand's
              language and making advertising genuine and relatable.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ads;
