import React from "react";
import { recieveBenefitsData } from "./data";
import { recieveOutsourcingData } from "./data";
import { recieveHiringData } from "./data";
import "./index.css";
import { SectionWrapper } from "../../hoc";
import { fadeIn } from "../../utils/motion";
import { motion } from "framer-motion";

const Recieve = () => {
    return (
        <div className="h-auto md:h-[200vh] bg-[#F7F4EC]">
            <h1 className="md:flex justify-center md:p-[4rem] text-center font-black text-5xl">With us, <span className="text-[#F25878] md:px-3"> you'll recieve...</span></h1>
            <div className="grid grid-rows-3 gap-y-8 md:flex justify-center text-[#F8F8F8] mx-auto px-[2.5vw] md:px-0 md:w-[80%]">
                <div className="md:w-[25%]">
                    <div>
                        <h1 className="md:rounded-tl-[5rem] rounded-t-[5rem] md:rounded-t-[0rem] border-2 border-[#F7F4EC] bg-[#6C63FF] font-black flex justify-center text-2xl p-[3rem]">Benefits</h1>
                    </div>
                    {recieveBenefitsData.map((item, index) => (
                        <motion.div 
                            className="bg-[#958FFF] border-[0.3px] border-[#F7F4EC] h-[10rem] text-lg"
                            variants={fadeIn("up", "tween", 0.4*index, 1)}
                        >
                            <h2 className="p-[3rem] flex text-center justify-center font-bold items-center h-full">{item.value}</h2>
                        </motion.div>
                    ))}
                    <motion.div 
                        className="md:rounded-bl-[5rem] rounded-b-[5rem] md:rounded-b-[0rem] bg-[#958FFF] border-[0.3px] border-[#F7F4EC] h-[10rem] text-lg"
                        variants={fadeIn("up", "tween", 2.5, 1)} 
                    >
                        <h1 className=" p-[3rem] flex justify-center font-bold items-center h-full">Growth Acceleration</h1>
                    </motion.div>
                </div>
                <div className="md:w-[37.5%]">
                    <div>
                        <h1 className="rounded-t-[5rem] md:rounded-t-[0rem] text-center border-2 border-[#F7F4EC] bg-[#FFB005] font-black flex justify-center text-2xl py-[3rem]">Outsourcing with Us</h1>
                    </div>
                    {recieveOutsourcingData.map((item, index) => (
                        <motion.div 
                            className="bg-[#FFC64B] border-[0.3px] border-[#F7F4EC] h-[10rem] text-lg"
                            variants={fadeIn("up", "spring", index*0.5, 0.75)}
                        >
                            <h2 className="flex justify-start p-[3rem] items-center h-full">{item.value}</h2>
                        </motion.div>
                    ))}
                    <motion.div 
                        className="bg-[#FFC64B] border-[0.3px] border-[#F7F4EC] h-[10rem] text-lg rounded-b-[5rem] md:rounded-b-[0rem]"
                        variants={fadeIn("up", "spring", 2.5, 0.75)}
                    >
                        <h1 className="flex justify-start p-[3rem] items-center h-full">✔️  Propel your agency's growth with our versatile team and resources.</h1>
                    </motion.div>
                </div>
                <div className="md:w-[37.5%]">
                    <div>
                        <h1 className="md:rounded-tr-[5rem] rounded-t-[5rem] md:rounded-t-[0rem] text-center border-2 border-[#F7F4EC] bg-[#F25878] font-black flex justify-center text-2xl py-[3rem]">Hiring an Individual</h1>
                    </div>
                    {recieveHiringData.map((item, index) => (
                        <motion.div 
                            className="bg-[#FFA1A1] border-[0.3px] border-[#F7F4EC] h-[10rem] text-lg "
                            variants={fadeIn("up", "spring", index*0.5, 0.75)}    
                        >
                            <h2 className="flex justify-start p-[3rem] items-center h-full">{item.value}</h2>
                        </motion.div>
                    ))}
                    <motion.div 
                        className=" md:rounded-br-[5rem] rounded-b-[5rem] md:rounded-b-[0rem] bg-[#FFA1A1] border-[0.3px] border-[#F7F4EC] h-[10rem] text-lg"
                        variants={fadeIn("up", "spring", 2.5, 0.75)}    
                    >
                        <h1 className="flex justify-start p-[3rem] items-center h-full">❌  Juggling management may hinder client acquisition.</h1>
                    </motion.div>
                </div>
                
            </div>
            <div>
                <h1 className="md:flex justify-center text-center font-black text-4xl md:text-5xl mt-[4rem]">50% <span className="text-[#F25878] md:px-3">less Cost</span>, 100% <span className="text-[#6C63FF] md:px-3">more Success.</span></h1>
            </div>
        </div>
    )
}

export default SectionWrapper(Recieve);