import React,{useState} from "react";
import {motion} from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";

const Identity = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);

    return (
        <div className="h-[80vh] lg:h-[130vh] text-white bg-[#1D1E20]">
            <h1 className="mx-auto text-center font-black pt-[4rem] text-3xl lg:text-7xl">
                THE<span className="font-extrabold tracking-wider text-fill-3 m-4">INSTINKTIVE </span> 
            </h1>
            <h1 className="mx-auto text-center font-black p-3 text-3xl lg:text-7xl">
              <span className="text-fill-4">IDENTITY</span>
            </h1>
            <div>
                <img src="assets/Fingerprint.svg" className="absolute left-1/2 transform -translate-x-1/2 mt-20 w-[40%] lg:w-[28%]"/>
                <img src="assets/brandele.svg" className="absolute right-[26%] lg:right-[28%] mt-[7rem] lg:mt-[9rem] w-[22%] lg:w-[20%]"/>
                <img src="assets/creativeele.svg" className="absolute left-[22%] lg:left-[25%] mt-[8rem] lg:mt-[14rem] w-[25%] lg:w-[20%]"/>
                <img src="assets/techLine.svg" className="absolute right-[22%] lg:right-[23%] mt-[12rem] lg:mt-[20rem] w-[25%] lg:w-[20%"/>
                <img src="assets/strategizele.svg" className="absolute left-[25%] lg:left-[23%] mt-[12rem] lg:mt-[25rem] w-[25%] lg:w-[25%]"/>
            </div>
            <div className="ml-[76%] lg:ml-[73%] mt-[6.8rem] lg:mt-[9rem] text-center lg:text-left">
                <h1 
                    className="text-[#A66BE1] ml-[25%]] font-bold text-xs lg:text-2xl cursor-pointer"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}    
                >
                    YOUR BRAND, ALIVE...
                </h1>
                {isHovered && (
                    <motion.div 
                        className="absolute w-1/4  text-white rounded-lg md:w-1/4 text-xs md:text-lg right-2 md:right-9 p-2 bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10"
                        initial={{ opacity: 0, x: -10 }} // Initial styles
                        animate={{ opacity: 1, x: 0 }} // Animation styles
                        transition={{ delay: 0.2, duration: 0.5 }}
                    >
                    Its's us, the spark that makes your brand sizzle, adding the Instinktive vibe that sets it on fire.- animation
                    </motion.div>
                )}
            </div>
            <div 
                className="ml-[6%] lg:ml-[11%] mt-[-1.5rem] lg:mt-[3rem] text-center lg:text-left"
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)} 
            >
                <h1 className="text-[#F25878] font-bold text-xs lg:text-2xl lg:w-full w-[10%] cursor-pointer">CREATIVE VIBES.</h1>
                {isHovered2 && (
                    <motion.div 
                        className="absolute w-1/4 text-white rounded-lg md:w-1/4 text-xs lg:text-lg p-2 mt-0 lg:mt-3 bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10"
                        initial={{ opacity: 0, x: -10 }} // Initial styles
                        animate={{ opacity: 1, x: 0 }} // Animation styles
                        transition={{ delay: 0.2, duration: 0.5 }}
                    >
                    We bring the cool creativity that speaks to your audience, making your brand pop in a world of dull.                    
                    </motion.div>
                )}
            </div>
            <div 
                className="ml-[80%] lg:ml-[78%] mt-[3rem] lg:mt-[9.5rem]"
                onMouseEnter={() => setIsHovered3(true)}
                onMouseLeave={() => setIsHovered3(false)} 
            >
                <h1 className="text-[#00DBFF] font-bold text-xs lg:text-2xl lg:w-full w-[10%] cursor-pointer">TECH THRIVES</h1>
                {isHovered3 && (
                    <motion.div 
                        className="absolute w-1/4 lg:w-1/5 text-white rounded-lg md:w-1/5 text-xs lg:text-lg p-2 right-2 md:right-9 bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10"
                        initial={{ opacity: 0, x: -10 }} // Initial styles
                        animate={{ opacity: 1, x: 0 }} // Animation styles
                        transition={{ delay: 0.2, duration: 0.5 }}
                    >
                    Powering up your brand with AI tricks, making it a digital rockstar in a tech-savvy crowd.                   
                    </motion.div>
                )}
            </div>
            <div 
                className="ml-[5%] lg:ml-[12%] mt-[-1.3rem] lg:mt-[2rem]"
                onMouseEnter={() => setIsHovered4(true)}
                onMouseLeave={() => setIsHovered4(false)}
            >
                <h1 className="text-[#FFB005] font-bold text-xs lg:text-2xl cursor-pointer">STRATEGIZE</h1>
                {isHovered4 && (
                    <motion.div 
                        className="absolute w-1/3 lg:w-1/4 text-center lg:text-left bg-gray-400 bg-clip-padding text-white rounded-lg md:w-1/4 text-xs lg:text-lg p-2 backdrop-filter backdrop-blur-sm bg-opacity-10 mt-0 lg: mt-3"
                        initial={{ opacity: 0, x: -10 }} // Initial styles
                        animate={{ opacity: 1, x: 0 }} // Animation styles
                        transition={{ delay: 0.2, duration: 0.5 }}
                    >
                        We're the wizards behind the scenes, conjuring strategies that make your brand stand out and be heard.
                    </motion.div>
                )}   
            </div>
        </div>
    )
}

export default SectionWrapper(Identity, "identity");