import React from "react";
import { ContentCard } from "./contentCard";
import { WrappedContentCard } from "./cardWrappers";
const BrandStrategy = () => {
    return (
        <div className="h-full md:h-[200vh] w-screen bg-[#1D1F21] text-[#F7F4EC]">
            <h1 className="font-black text-4xl md:text-[9rem] leading-normal flex justify-center mx-auto w-1/2 text-center pt-[10rem]">BRAND STRATEGY</h1>
            <WrappedContentCard />
        </div>
    )
}

export default BrandStrategy;