const SearchIcon = require("../../assets/searchIcon.webp");
const AudienceIcon = require("../../assets/audienceIcon.webp");
const CapIcon = require("../../assets/capIcon.webp");
const TargetIcon = require("../../assets/targetIcon.webp");
const AimIcon = require("../../assets/aimIcon.webp");
const GrowthIcon = require("../../assets/growthIcon.webp");
const BrandStrategy = require("../../assets/BrandStrategy.webp");
const Seo = require("../../assets/Seo.webp");
const EmailOutreach = require("../../assets/EmailOutreach.webp");
const SocialMediaEngagement = require("../../assets/SocialMediaEngagement.webp");
const SearchDisplayAds = require("../../assets/SearchDisplayAds.webp");
const TraditionalMarketing = require("../../assets/TraditionalMarketing.webp");
const ViralityChannels = require("../../assets/ViralityChannels.webp");


const MarketingData = [
    {
        icon: SearchIcon,
        step: "STEP 1",
        title: "Research and Audit",
        color: "#F25878"
    },
    {
        icon: AudienceIcon,
        step: "STEP 2",
        title: "Audience Discovery",
        color: "#A66BE1"
    },
    {
        icon: TargetIcon,
        step: "STEP 3",
        title: "Market Entry Plan",
        color: "#FBBEBE"
    },
    {
        icon: CapIcon,
        step: "STEP 4",
        title: "Defining the 7 P's",
        color: "#00DBFF"
    },
    {
        icon: AimIcon,
        step: "STEP 5",
        title: "Execution",
        color: "#FFB005"
    },
    {
        icon: GrowthIcon,
        step: "STEP 6",
        title: "Data Driven Growth",
        color: "#6C63FF"
    },
]

const brandData = [
    {
        image: BrandStrategy,
        tagline: "WHERE BRILLIANCE MEETS INFLUENCE",
        description: "Want your brand to shine in a crowded market? That's where brand strategy comes in. It's about finding your unique voice and using savvy marketing to connect with the right audience. The result? You leave your competitors in the dust.",
    }
]

const discoverabilityData = [
    {
        image: Seo,
        title1: "SEO",
        title2: "Optimization",
        color: "#F25878",
        tagline: "ELEVATE YOUR BRAND'S VISIBILITY WITH SEO MASTERY",
        description: "In SEO, we're steps ahead. We find resonating keywords, create engaging blogs, and build high-quality backlinks to boost your brand's digital authority, helping it rise to the top."
    },
    {
        image: EmailOutreach,
        title1: "Email",
        title2: "Outreach",
        color: "#FFB005",
        tagline: "MASTERING THE ART OF INBOX IMPACT",
        description: "Our email outreach service crafts attention-grabbing messages that spark interest and drive action. We know the science behind successful email campaigns, ensuring your brand's voice is heard loud and clear."
    },
    {
        image: SocialMediaEngagement,
        title1: "Social Media",
        title2: "Engagement",
        color: "#9747FF",
        tagline: "  WHERE CLICKS, COMMENTS, AND CONNECTIONS CONVERGE",
        description: "We master social engagement, sparking conversations and building community. With flair and finesse, we captivate and share your brand's story across platforms."
    },
]

const mediaData = [
    {
        image: SearchDisplayAds,
        title1: "Search and",
        title2: "Display Ads",
        color: "#00DBFF",
        tagline: "DONATE THE DIGITAL SPACE WITH PRECISION-TARGETED ADS",
        description: "In a click-driven world, we master digital dominance, precision-targeting your audience. When your customers search or scroll, you're there, grabbing attention like a pro."
    },
    {
        image: TraditionalMarketing,
        title1: "Traditional",
        title2: "Marketing",
        color: "#F25878",
        tagline: "CLASSIC MEETS CONTEMPORARY",
        description: "We modernize classic methods like press, radio, and outdoor advertising for your brand's revival. The perfect blend of old-school charm and cutting-edge impact."
    },
    {
        image: ViralityChannels,
        title1: "Virality",
        title2: "Channels",
        color: "#9747FF",
        tagline: "UNLEASH ONLINE TRENDS TO AMPLIFY YOUR BRAND",
        description: "Ride the viral wave with Influencer and Meme Marketing. We turn internet trends into brand gold, ensuring humor and relatability. Get ready to trend for the right reasons."
    },
]

export {MarketingData, brandData, discoverabilityData, mediaData};