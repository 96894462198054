import React,{useState, useEffect} from "react";
import { ContentCard2 } from "./contentCard";
import { WrappedContentCard2 } from "./cardWrappers";

const Discoverability = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
    return (
        <div
        id="organicdiscoverability"
      className="relative bg-[#F7F4EC] text-[#1D1F21] overflow-hidden"
      style={{ backgroundPosition: `center ${-scrollPosition / 2}px` }}
    >
            <h1 className="font-black text-4xl md:text-[8rem] leading-normal flex justify-center mx-auto w-1/2 text-center py-[10rem]">ORGANIC DISCOVERABILITY</h1>
            <WrappedContentCard2 />
        </div>
    )
}

export default Discoverability;