import React, {useState, useEffect} from "react";
import Footer from "../components/GetinTouch/footer";
import Navbar from "../components/Home/navbar";
import Introduction from "../components/GetinTouch/introduction";
import SyncLoader from "react-spinners/SyncLoader";
import loadinglogo from "../assets/loading.webp";
import {NavLink} from "react-router-dom";
import "./index.css";
const GetInTouch = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    useEffect(() => {
        const text = document.querySelector(".text p");
        if (text) {
          text.innerHTML = text.innerText.split("").map(
            (char, i) => 
            `<span style="transform:rotate(${i*8}deg)">${char}</span>`
          ).join("")
        }
      }, []);
    return (
        <div className="bg-black">
            {loading?
                <div className="h-screen w-screen flex bg-black flex-col justify-center items-center">
                    <img src={loadinglogo} alt="loading"/>
                    <SyncLoader
                        size={20}
                        color={"#fff"}
                        loading={loading}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            :
                <div>
                    <Navbar/>
                    <Introduction />
                    {/* <Footer /> */}
                    <div className="px-[5vw] md:px-[2.5vw] lg:px-[0vw] md:w-full z-20 bottom-0 bg-[#202427] mb-0">
                        <div className="flex flex-col text-white max-w-screen-2xl md:mx-auto lg:px-[4rem]">
                            <div className="flex flex-col py-[5vh] md:py-[7.5vh]">
                                <h1 className="text-white text-4xl md:text-7xl font-black">LET'S <span className="text-fill-3">CONNECT</span></h1>
                                <p className="inline-flex mt-[2.5vh]">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                </svg>
                            </span>(M) 9081991113, (O) 0265-2786486
                                </p>
                                <p className="inline-flex mt-[2.5vh]">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                            </span>contact@instinkt.in
                                </p>
                                <p className="inline-flex mt-[2.5vh]">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>
                            </span>SB-43, Arpan Complex, Near Passport Office, Nizampura, Vadodara-390002.
                                </p>
                            </div>
                            <div className="h-96 w-full mb-[7.5vh]">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14762.094945107412!2d73.180909!3d22.3338449!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc9893652509b%3A0x67d47b6d79dcb4cb!2sInstinkt%20Media%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1700245305593!5m2!1sen!2sin"
                                    className="h-full w-full rounded-xl"
                                    allowFullScreen>
                                </iframe>
                            </div>
                            <div className="grid md:grid-cols-11 grid-cols-1 gap-[2.5vh] md:gap-0 md:w-full w-[75vw]">
                                <div className="w-full col-span-3">
                                    <p className="text-xl font-black pb-1.5">NAVIGATION</p>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">ABOUT</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">PRICES</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">RESOURCES</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">INTERNSHIPS</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">CONTACT</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                </div>
                                <div className="col-span-1"></div>
                                <div className="w-full col-span-3">
                                    <p className="text-xl font-black pb-1.5">SERVICES</p>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">GRAPHIC DESIGN</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">SHOOTS AND UGC ADS</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">WEB AND APP DEVELOPMENT</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">WORKFLOW AUTOMATION</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">AI IN MARKETING</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">AI CHATBOTS AND ASSISTANTS</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">BRAND STRATEGY</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">ORGANIC DISCOVERABILITY</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                    <div className="my-3">
                                        <div className="grid grid-cols-12 text-sm text-[#7c7c7c]">
                                            <p className="col-span-9 text-left font-black">MEDIA ALLOCATION & ADVERTISING</p>
                                            <p className="col-span-3 text-right pr-7 font-bold">LINK</p>
                                        </div>
                                        <hr className="mt-2 border-[#7c7c7c]"/>
                                    </div>
                                </div>
                                <div className="col-span-1"></div>
                                <div className="w-full col-span-3">
                                    <p className="text-xl font-black pb-1.5">FOLLOW US</p>
                                    <div className="my-3 inline-flex gap-5">
                                        <a href="/" className="bg-white rounded-full p-[0.5em]">
                                            <svg fill="#202427" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 320 512"><path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/></svg>
                                        </a>
                                        <a href="/" className="bg-white rounded-full p-[0.5em]">
                                            <svg fill="#202427" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>
                                        </a>
                                        <a href="/" className="bg-white rounded-full p-[0.5em]">
                                            <svg fill="#202427" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="text-sm font-light py-[2.5vh] mt-[2.5vh]">
                                <p className="tracking-widest">© 2023 INSTINKT. ALL RIGHTS RESERVED.</p>
                            </div>
                        </div>
                    </div>

                </div>
            }

        </div>
    )
}

export default GetInTouch;