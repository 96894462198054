const benefitsData = [
    {
        number: "1",
        title1: "Unlock Your Agency's",
        title2: "Full Potential",
        color: "#F25878",
        description: "Are you a budding agency looking to make your mark? Let us supercharge your growth"
    },
    {
        number: "2",
        title1: "Get a Dedicated Team",
        title2: "Not Just a Professional",
        color: "#00DBFF",
        description: "When you team up with us, you're not just hiring one person. You get a dedicated team of experts, including a manager, all at a jaw-dropping 50% less than traditional hiring costs."
    },
    {
        number: "3",
        title1: "Focus on Sales",
        title2: "We Handle Delivery",
        color: "#FFB005",
        description: "With us by your side, you're free to concentrate on what you do best - bringing in those game-changing deals. Leave the heavy lifting to us. We're all about delivering top-notch results to your clients."
    },
    {
        number: "4",
        title1: "Quality Deliverables",
        title2: "Every Time",
        color: "#A66BE1",
        description: "Our team is obsessed with excellence. We pride ourselves on delivering nothing short of exceptional quality. Your clients will thank you for it."
    }
]

const recieveBenefitsData = [
    {
        value: "Cost Efficiency"
    },
    {
        value: "Diverse Skill Sets"
    },
    {
        value: "Management Included"
    },
    {
        value: "Number of People"
    },
    {
        value: "Ability for Diverse Projects"
    },
]

const recieveOutsourcingData = [
    {
        value: "✔️  Designer, Full-Stack Dev, a WordPress Pro, and a Manager – all at a dazzling up to 50% less expenditure."
    },
    {
        value: "✔️  Access a wide range of skills tailored to your needs."
    },
    {
        value: "✔️  We provide a dedicated Manager for your projects."
    },
    {
        value: "✔️  Benefit from a team of 3-4 professionals."
    },
    {
        value: "✔️  Multi-skilled team with a wider variety of projects."
    },

]

const recieveHiringData = [
    {
        value: "❌  Your investment gets you just a WordPress Developer, for double the price."
    },
    {
        value: "❌  Limited to individual's expertise."
    },
    {
        value: "❌  Requires individual management."
    },
    {
        value: "❌  Limited to an individual"
    },
    {
        value: "❌  Limited to projects aligned with individual's skillset."
    },
]

export {benefitsData, recieveBenefitsData, recieveOutsourcingData, recieveHiringData};