import React from "react";
import website from "../../assets/website.webp";
import app from "../../assets/app.webp";

const Webappdevelopment = () => {
  return (
    <div id="webappdevelopment" className="bg-[#1D1F21]">
      <div className="max-w-7xl mx-auto py-32">
        <div className="text-[#F7F4EC] text-5xl lg:text-[8rem] text-center font-black leading-tight pb-32">
          <h1>WEB & APP</h1>
          <h1>DEVELOPMENT</h1>
        </div>
        <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
          <div className="p-8 lg:pr-8 lg:m-auto">
            <div
              style={{
                "--image-url": `url(${website})`,
                backgroundSize: "100%",
              }}
              className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
            ></div>
          </div>
          <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-[#F7F4EC] px-8 lg:pl-8 lg:m-auto mx-auto">
            <p className="text-5xl lg:text-7xl font-[800]">
              Website that <span className="text-red-400">converts.</span>
            </p>
            <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
              TURNING CLICKS INTO CUSTOMERS WITH INTUITIVE DESIGN.
            </p>
            <hr />
            <p className="mt-8 lg:pr-28 lg:text-lg">
              We craft websites for conversions, not just looks. With intuitive
              design, smooth navigation, and compelling calls to action, our
              goal is turning clicks into loyal customers.
            </p>
          </div>
        </div>
        <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
          <div className="p-8 lg:pr-8 lg:m-auto">
            <div
              style={{ "--image-url": `url(${app})`, backgroundSize: "100%" }}
              className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
            ></div>
          </div>
          <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-[#F7F4EC] px-8 lg:pl-8 lg:m-auto mx-auto">
            <p className="text-5xl lg:text-7xl font-[800]">
              Appealing <span className="text-purple-500">App.</span>
            </p>
            <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
              KEEP THEM COMING BACK.
            </p>
            <hr />
            <p className="mt-8 lg:pr-28 lg:text-lg">
              We craft indispensable apps, creating seamless experiences for
              lasting connections with your brand. Apps that users can't live
              without.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Webappdevelopment;
