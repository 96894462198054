import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { zoomInnorepeat, fadeIn, textVariant } from "../../utils/motion";
import "./index.css";
const WayToWin = () => {
  return (
    <div className="bg-[#1D1E20] text-white md:h-[65rem] lg:h-[150rem] border-solid rounded-[6rem] mt-[18rem] md:mt-[4rem] lg:mt-[0rem] py-[4rem]">
      <h1 className="mx-auto text-center font-black pt-[4rem] text-3xl lg:text-7xl ">
        OUR
        <span className="font-extrabold tracking-wider text-fill-3 m-4">
          PRACTICAL
        </span>
      </h1>
      <h1 className="mx-auto text-center font-black p-3 text-3xl lg:text-7xl">
        <span className="font-extrabold tracking-wider text-fill-3 m-4">
          WAY
        </span>{" "}
        <span className="text-fill-4">TO WIN</span>
      </h1>
      <div className="mt-20 lg:mt-20">
        {/* <img src="assets/Roadmap.svg" class="absolute left-1/2 transform -translate-x-1/2 w-1/2 lg:w-[37%] mt-12"/> */}
        <svg
          width="566"
          height="1715"
          viewBox="0 0 566 1715"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute left-1/2 transform -translate-x-1/2 w-1/2 lg:w-[37%] md:mt-12 -mt-[33rem]"
        >
          <path
            class="path"
            d="M42.5 10L425.13 309.816C480.846 353.473 474.813 439.608 413.556 475.073L149.687 627.839C83.1132 666.382 83.1703 762.52 149.79 800.984L426.038 960.479C490.256 997.556 493.196 1089.18 431.488 1130.3L153.719 1315.39C98.1624 1352.41 93.79 1432.43 144.983 1475.29L420 1705.5"
            stroke="white"
            stroke-width="6"
            stroke-linejoin="round"
            stroke-dasharray="12 12"
          />
          <motion.rect
            x="367"
            y="1653"
            width="61"
            height="62"
            rx="30.5"
            fill="#72D1C7"
            variants={zoomInnorepeat(2.6, 0.8)}
          />
          <motion.path
            d="M399.192 1678.9C405.72 1678.9 409.976 1681.07 409.976 1686.48C409.976 1691.7 405.72 1694.32 397.816 1694.32C388.216 1694.32 384.376 1690.58 384.376 1683.34C384.376 1674.06 389.88 1671.28 396.472 1671.28C402.04 1671.28 405.752 1672.05 408.472 1672.62V1677.2C405.304 1676.59 402.04 1675.86 397.88 1675.86C393.336 1675.86 390.84 1676.78 390.264 1681.33C391.992 1679.98 394.968 1678.9 399.192 1678.9ZM397.176 1689.74C402.616 1689.74 404.216 1688.34 404.216 1686C404.216 1683.66 402.648 1682.29 397.176 1682.29C392.248 1682.29 390.136 1683.63 390.136 1686C390.136 1688.34 392.088 1689.74 397.176 1689.74Z"
            fill="white"
            variants={zoomInnorepeat(2.6, 0.8)}
          />
          <motion.rect
            x="156"
            y="1265"
            width="61"
            height="62"
            rx="30.5"
            fill="#FAEDCB"
            variants={zoomInnorepeat(2.2, 0.8)}
          />
          <motion.path
            d="M189.748 1290.9C195.54 1290.9 198.964 1293.62 198.964 1298.42C198.964 1303.98 194.228 1306.32 186.74 1306.32C179.316 1306.32 173.556 1304.85 173.556 1298.22H179.316C179.316 1300.75 181.972 1301.49 186.9 1301.49C191.668 1301.49 193.204 1300.05 193.204 1297.78C193.204 1295.66 192.052 1294.19 187.668 1294.19C185.46 1294.19 183.252 1294.64 179.412 1296.18L174.676 1295.25L177.556 1283.6H197.364V1288.18H181.844L180.404 1292.94C183.7 1291.54 186.1 1290.9 189.748 1290.9Z"
            fill="#1D1E20"
            variants={zoomInnorepeat(2.2, 0.8)}
          />
          <motion.rect x="414" y="948" width="61" height="58" rx="29" fill="#A66BE1" variants={zoomInnorepeat(1.7, 0.8)}/>
          <motion.path
            d="M457.424 978.488V983.352H452.944V987H447.184V983.352H431.376V978.488L447.184 964.6H452.944V978.488H457.424ZM437.584 978.488H447.184V970.36L437.584 978.488Z"
            fill="white"
            variants={zoomInnorepeat(1.7, 0.8)}
          />
          <motion.rect x="73" y="647" width="61" height="58" rx="29" fill="#00DBFF" variants={zoomInnorepeat(1.5, 0.8)}/>
          <motion.path
            d="M112.508 674.8C114.652 675.824 115.964 677.36 115.964 679.696C115.964 684.656 111.452 686.32 103.58 686.32C95.804 686.32 90.556 684.656 90.556 677.52H96.316C96.316 681.104 98.972 681.488 103.9 681.488C108.476 681.488 110.204 681.104 110.204 678.96C110.204 676.72 108.476 676.528 100.7 676.528V673.072C108.316 673.072 110.204 672.88 110.204 670.64C110.204 668.688 108.86 668.112 103.9 668.112C98.428 668.112 96.316 668.688 96.316 672.08H90.556C90.556 664.944 95.804 663.28 103.58 663.28C111.452 663.28 115.964 664.944 115.964 669.904C115.964 672.24 114.652 673.776 112.508 674.8Z"
            fill="white"
            variants={zoomInnorepeat(1.5, 0.8)}
          />
          <motion.rect x="428" y="320" width="61" height="58" rx="29" fill="#FFB005" variants={zoomInnorepeat(1.3, 0.8)}/>
          <motion.path
            d="M470.964 343.32C470.964 351.992 455.028 349.72 453.62 354.168H470.644V359H445.556V356.088C445.556 346.776 465.204 348.856 465.204 343.768C465.204 341.912 464.02 341.112 459.06 341.112C454.1 341.112 451.828 341.848 451.828 345.4H446.068C446.068 337.944 451.316 336.28 458.74 336.28C466.452 336.28 470.964 337.752 470.964 343.32Z"
            fill="white"
            variants={zoomInnorepeat(1.3, 1.3)}
          />
          <motion.rect x="35" width="61" height="58" rx="29" fill="#F25878" variants={zoomInnorepeat(0.8, 0.8)}/>
          <motion.path
            d="M68.316 34.168H75.996V39H54.876V34.168H62.588L62.62 23.608C60.188 25.752 57.212 25.752 54.876 25.752V20.888C58.172 20.888 62.556 20.888 62.556 16.6H68.316V34.168Z"
            fill="white"
            variants={zoomInnorepeat(0.8, 0.8)}
          />
        </svg>
        <motion.div
          className="w-[40%] lg:w-[20%] ml-[60%] lg:ml-[60%]"
          variants={fadeIn("left", "spring", 0.5, 0.75)}
        >
          <h1 className="font-black text-lg lg:text-4xl text-[#F25878] my-2 lg:my-8">
            DREAM IT
          </h1>
          <p className="font-thin text-xs lg:text-lg">
            We turn your dreams into goals by understanding your vision and
            creating a game plan together.
          </p>
        </motion.div>
        <motion.div
          className="w-[40%] lg:w-[25%] ml-[5%] lg:ml-[15%] mt-[0rem] lg:mt-[12rem]"
          variants={fadeIn("right", "spring", 1, 0.75)}
        >
          <h1 className="font-black text-lg lg:text-4xl text-[#FFB005] my-2 lg:my-8">
            LEARN IT
          </h1>
          <p className="font-thin text-xs lg:text-lg">
            We immerse ourselves in learning, gather cool info, and are ready to
            rock your goals with research and preparation.
          </p>
        </motion.div>
        <motion.div
          className="w-[40%] lg:w-[20%] ml-[60%] mt-[0rem] lg:mt-[12rem]"
          variants={fadeIn("left", "spring", 1.5, 0.75)}
        >
          <h1 className="font-black text-lg lg:text-4xl text-[#00DBFF] my-2 lg:my-8">
            PLAN IT
          </h1>
          <p className="font-thin text-xs lg:text-lg">
            We craft a flawless plan, a detailed map to success, with no room
            for detours..
          </p>
        </motion.div>
        <motion.div
          className="w-[40%] lg:w-[20%] ml-[5%] lg:ml-[15%] mt-[0rem] lg:mt-[10rem]"
          variants={fadeIn("right", "spring", 2, 0.75)}
        >
          <h1 className="font-black text-lg lg:text-4xl text-[#A66BE1] my-2 lg:my-8">
            DO IT
          </h1>
          <p className="font-thin text-xs lg:text-lg">
            We turn plans into action, infusing boundless energy and enthusiasm
            to bring your dreams to life.
          </p>
        </motion.div>
        <motion.div
          className="w-[40%] lg:w-[23%] ml-[60%] mt-[2rem] lg:mt-[10rem]"
          variants={fadeIn("left", "spring", 2.5, 0.75)}
        >
          <h1 className="font-black text-lg lg:text-4xl text-[#FAEDCB] my-2 lg:my-8">
            REFINE IT
          </h1>
          <p className="font-thin text-xs lg:text-lg">
            We keep improving, leveling up with each move, constantly refining
            our approach for exceptional results.
          </p>
        </motion.div>
        <motion.div
          className="w-[40%] lg:w-[25%] ml-[5%] lg:ml-[15%] mt-[0rem] lg:mt-[13rem]"
          variants={fadeIn("right", "spring", 3, 0.75)}
        >
          <h1 className="font-black text-lg lg:text-4xl text-[#72D2C7] my-2 lg:my-8">
            REPEAT IT
          </h1>
          <p className="font-thin text-xs lg:text-lg">
            We keep going, creating fresh magic. Your dreams plus our hustle
            equals unstoppable success!
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default SectionWrapper(WayToWin);
