import React, { useEffect, useState } from "react";
import Navbar from "../components/Home/navbar";
import Footer from "../components/Home/footer";
import loadinglogo from "../assets/loading.webp";
import SyncLoader from "react-spinners/SyncLoader";
import Introduction from "../components/Automate/Introduction";
import TaskwithAi from "../components/Automate/TaskwithAi";
import Workflowautomation from "../components/Automate/Workflowautomation";
import AIinmarketing from "../components/Automate/AIinmarketing";
import Chatbotassistant from "../components/Automate/Chatbotassistant";

const Automate = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div>
      {loading ? (
        <div className="h-screen w-screen bg-black flex flex-col justify-center items-center">
          <img src={loadinglogo} alt="loading" />
          <SyncLoader
            size={20}
            color={"#fff"}
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div>
          <Navbar />
          <Introduction />
          <TaskwithAi />
          <Workflowautomation />
          <AIinmarketing />
          <Chatbotassistant />
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Automate;
{
  /* <div className="h-60 lg:h-screen bg-[#202427]">
        <div className="max-w-7xl mx-auto py-[0vh] lg:py-[25vh] relative top-0">
          <div className="grid grid-cols-12 px-[2.5vw] text-white lg:gap-24">
            <h1 className="ml-3 col-start-1 mt-7 lg:mt-0 lg:col-start-2 text-4xl lg:text-[6rem] font-[800] col-span-full">
              Time to take
            </h1>
            <h1 className="m-1 col-start-3 lg:col-start-4 text-4xl lg:text-[6rem] font-[800] col-span-full">
              your flight on
            </h1>
            <h1 className="m-1 col-start-6 lg:col-start-7 text-4xl lg:text-[6rem] font-[800] col-span-full">
              <span className="text-yellow-500 italic">Auto-pilot</span>
            </h1>
            <div></div>
          </div>
        </div>
      </div> */
}
