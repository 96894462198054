import React, {useEffect, useState} from "react";
import Navbar from "../components/Home/navbar";
import Footer from "../components/Home/footer";
import "./index.css";
import SyncLoader from "react-spinners/SyncLoader";
import loadinglogo from "../assets/loading.webp";
import { motion } from "framer-motion";
import { SectionWrapper } from "../hoc";
import { fadeIn, textVariant } from "../utils/motion";
import Introduction from "../components/Creative/Introduction";
import Trends from "../components/Creative/Trends";
import Graphicdesign from "../components/Creative/Graphicdesign";
import Ads from "../components/Creative/Ads";
import Webappdevelopment from "../components/Creative/Webappdevelopment";

const Creative = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    return (
        <div>
            {loading?<div className="h-screen w-screen bg-black flex flex-col justify-center items-center">
                <img src={loadinglogo} alt="loading"/>
                <SyncLoader
                    size={20}
                    color={"#fff"}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div> :
            <div>
                <Navbar/>
                <Introduction />
                <Trends />
                <Graphicdesign />
                <Ads />
                <Webappdevelopment />
                <Footer/>
            </div>}
        </div>
    )
}

export default SectionWrapper(Creative, "creative");