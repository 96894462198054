import React from "react";
import {motion} from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";

const Introduction = () => {
    return (
        <div className="overflow-hidden">
            <div className="max-w-full lg:h-full h-[100rem] absolute top-0 -z-30">
                    <video loop autoPlay={true} className="w-auto md:h-auto h-[100rem] object-cover justify-center">
                        <source src="/assets/creative.mp4" type="video/mp4"/>
                    </video>
            </div>
                <div className="md:h-full h-[20rem] bg-[#1D1F21] bg-opacity-50 pt-24 md:pt-0">
                    <div className="max-w-7xl mx-auto lg:py-[22.5vh] relative top-0">
                        <div className="grid grid-cols-12 px-[2.5vw] text-[#F7F4EC] lg:gap-24 scale-90 md:scale-100">
                            <motion.h1 
                                className="col-start-2 lg:col-start-2 text-4xl lg:text-[7rem] font-[800] col-span-full"
                                variants={fadeIn("up", "tween", 0.5, 0.5)}
                            >
                                    Craft the visual
                            </motion.h1>
                            <motion.h1 
                                className="col-start-4 lg:col-start-4 text-4xl lg:text-[7rem] font-[800] col-span-full"
                                variants={fadeIn("up", "tween", 0.8, 0.5)}
                            >
                                heartbeat of
                            </motion.h1>
                            <motion.h1 
                                className="col-start-6 lg:col-start-6 text-4xl lg:text-[7rem] font-[800] col-span-full"
                                variants={fadeIn("up", "tween", 1.2, 0.5)}
                            >
                                your <span className="text-cyan-400 italic">brand.</span>
                            </motion.h1>
                        </div>
                        <motion.div 
                            className="mt-2 md:mt-[3rem] ml-[60%] mx-auto"
                            variants={fadeIn("up", "tween", 1.5, 0.5)}
                        >
                            <img src="/assets/marketinglineelement.svg" className="mx-auto"/>
                        </motion.div>
                    </div>
                </div>
        </div>
    )
}

export default SectionWrapper(Introduction);