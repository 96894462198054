import React from "react";
import task_image from "../../assets/automating-task-assignment.webp";
import schedule_image from "../../assets/appointment-scheduling.webp";
import staff_train_image from "../../assets/staff-training.webp";

const Workflowautomation = () => {
    return (
        <div id="workflowautomation" className="bg-[#202427]">
          <div className="max-w-7xl mx-auto py-32">
            <div className="text-white text-5xl lg:text-[8rem] text-center font-black leading-tight pb-32">
              <h1>Workflow</h1>
              <h1>Automation</h1>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${task_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-3 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-white px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  Automating Task{" "}
                  <span className="text-yellow-500">Assignments.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  LET AI TAKE THE WHEEL IN DISTRIBUTING TASKS
                </p>
                <hr />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  Boost productivity with our AI-driven task assignment system,
                  ensuring optimal task distribution for your team's success.
                </p>
              </div>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${schedule_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-white px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  Auto Appointment{" "}
                  <span className="text-red-400"> Scheduling.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  SAY GOODBYE TO SCHEDULING HASSLES
                </p>
                <hr />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  Your Calendar's New Best Friend. Never play the scheduling
                  puzzle again. Our automated scheduling system seamlessly books
                  appointments, making time management effortless.
                </p>
              </div>
            </div>
            <div className="h-screen grid grid-cols-1 lg:grid-cols-10">
              <div className="p-8 lg:pr-8 lg:m-auto">
                <div
                    style={{
                      "--image-url": `url(${staff_train_image})`,
                      backgroundSize: "100%",
                    }}
                    className={`col-span-4 m-auto aspect-square h-auto w-full lg:h-[28rem] lg:w-[28rem] rounded-[70px] bg-cover bg-no-repeat bg-[image:var(--image-url)]`}
                ></div>
              </div>
              <div className="lg:col-start-5 col-span-6 text-center lg:text-left text-white px-8 lg:pl-8 lg:m-auto mx-auto">
                <p className="text-5xl lg:text-7xl font-[800]">
                  Staff Training <span className="text-purple-500">Systems.</span>
                </p>
                <p className="text-lg lg:text-xl font-semibold mt-8 mb-3">
                  REVOLUTIONIZE YOUR TRAINING PROCESSES
                </p>
                <hr />
                <p className="mt-8 lg:pr-28 lg:text-lg">
                  Unlock your workforce's potential with dynamic Staff Training
                  Systems. Our AI-driven onboarding revolutionizes training,
                  inspiring growth and swiftly integrating new recruits.
                </p>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Workflowautomation;