import React from "react";
import {motion} from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";

const Trends = () => {
  return (
    <div className="lg:h-screen bg-neutral-300">
      <div className="max-w-7xl mx-auto py-[12.5vh] lg:py-[20vh]">
        <div className="grid grid-cols-12 px-[2.5vw] text-[#1D1F21] lg:gap-10">
          <motion.h1 
            className="col-start-2 lg:col-start-2 text-4xl lg:text-[7rem] font-[800] col-span-full lg:py-12"
            variants={fadeIn("up","tween", 0.5, 0.5)}
          >
            We don't just
          </motion.h1>
          <motion.h1 
            className="col-start-4 lg:col-start-4 text-4xl lg:text-[7rem] font-[800] col-span-full lg:pb-12"
            variants={fadeIn("up", "tween", 0.8, 0.5)}
          >
            follow trends.
          </motion.h1>
          <motion.h1 
            className="col-start-5 lg:col-start-5 text-4xl lg:text-[7rem] font-[800] col-span-full text-red-400"
            variants={fadeIn("up", "tween", 1.2, 0.5)}
          >
            We set them.
          </motion.h1>
          <motion.img
            src="../assets/orangeline.svg"
            className="col-start-6 col-span-full"
            variants={fadeIn("up", "tween", 1.5, 0.5)}
          />
          <div className="col-start-8 lg:col-start-9 font-medium text-xs lg:text-xl col-span-full">
            {/* <p>Get ready to dive into the</p>
            <p>future of brand aesthetics.</p> */}
            <div>
                <div className="flex">
                    <motion.span variants={fadeIn("up", "tween", 1.6, 0.4)}>Get </motion.span>
                    <motion.span className="px-1" variants={fadeIn("up", "tween", 1.7, 0.4)}>ready </motion.span>
                    <motion.span variants={fadeIn("up", "tween", 1.8, 0.4)}>to </motion.span>
                    <motion.span className="px-1" variants={fadeIn("up", "tween", 1.9, 0.4)}>dive </motion.span>
                    <motion.span variants={fadeIn("up", "tween", 2, 0.4)}>into </motion.span>
                </div>
                <div className="flex">
                    <motion.span variants={fadeIn("up", "tween", 2.1, 0.4)}>future </motion.span>
                    <motion.span className="px-1" variants={fadeIn("up", "tween", 2.2, 0.4)}>of </motion.span>
                    <motion.span variants={fadeIn("up", "tween", 2.3, 0.4)}>brand </motion.span>
                    <motion.span className="px-1" variants={fadeIn("up", "tween", 2.4, 0.4)}>aesthetics </motion.span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper(Trends);
