import React, {useEffect, useState} from "react";
import Introduction from "../components/Home/introduction";
import Navbar from "../components/Home/navbar";
import WayToWin from "../components/Home/waytowin";
import Services from "../components/Home/services";
import Footer from "../components/Home/footer";
import Identity from "../components/Home/identity";
import loadinglogo from "../assets/loading.webp";
import SyncLoader from "react-spinners/SyncLoader";

const Home = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    return (
        <div> {loading?<div className="h-screen w-screen bg-black flex flex-col justify-center items-center">
            <img src={loadinglogo} alt="loading"/>
            <SyncLoader
                size={20}
                color={"#fff"}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div> :
            <div className="overflow-hidden">
                <Navbar />
                <Introduction />
                <WayToWin />
                <Services />
                <Identity />
                <Footer />
            </div>
        }
        </div>
    )
}

export default Home;