import React from "react";
import { benefitsData } from "./data";
import { SectionWrapper } from "../../hoc";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/motion";

const Benefits = () => {
    return (
        <div className="bg-[#1D1F21] h-full md:h-[180vh] text-[#F7F4EC] pt-[6rem] pb-[3rem] md:pb-0 my-0 text-center md:text-left">
            {benefitsData.map((item, index) => (
                <div key={index} className="flex flex-col md:flex-row justify-center items-center mb-[6rem]">
                    <motion.div 
                        className="h-[10rem] w-[10rem] rounded-full px-[2.5vw] md:px-0 md:mx-[6rem]"
                        style={{ backgroundColor: item.color, display: "flex", alignItems: "center", justifyContent: "center" }}
                        variants={fadeIn("left", "tween", 0.4*index, 1)}
                    >
                        <h1 className="font-black text-6xl">{item.number}</h1>
                    </motion.div>
                    <motion.div 
                        className="md:w-1/2 px-[2.5vw] md:px-0 md:mx-[4rem]"
                        variants={fadeIn("right", "tween", 0.4*index, 1)}    
                    >
                        <h1 className="font-black text-5xl pb-3" style={{color: item.color}}>{item.title1}</h1>
                        <h1 className="font-black text-5xl">{item.title2}</h1>
                        <p className="font-thin pt-5 md:w-[75%]">{item.description}</p>
                    </motion.div>
                </div>
            ))}
        </div>
    )
}

export default SectionWrapper(Benefits);