import React, { useState } from "react";
import { HashRouter, NavLink, useNavigate } from "react-router-dom";
import "./index.css";
import { Link as ScrollLink } from "react-scroll";
import { FiArrowDownRight } from "react-icons/fi";
import { FiArrowUpRight } from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";

const Services = () => {
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleExpand2 = () => {
    setIsExpanded2(!isExpanded2);
  };
  const handleExpand3 = () => {
    setIsExpanded3(!isExpanded3);
  };
  return (
    <div className="container my-[6rem] max-w-screen-xl lg:mx-auto shadow-custom rounded-[4rem] h-[35rem] lg:h-[45rem] mx-2 overflow-scroll overflow-x-hidden">
      <h1 className="text-4xl lg:text-7xl flex justify-center font-black p-[2rem] lg:p-[4rem] text-[#1F1F1F]">
        SERV<span className="text-[#1F7EA1]">ICES</span>
      </h1>
      <div
        className={`border-2 max-w-5xl border-[#7C7C7C] my-5 lg:my-10 mx-5 lg:mx-auto ${
          isExpanded ? "rounded-[2rem]" : "rounded-[5rem] cursor-pointer overflow-hidden"
        }`}
      >
        <div
          className="flex justify-between items-center overflow-hidden"
          onClick={handleExpand}
        >
          <h1 className="z-[40] text-sm lg:text-2xl text-[#7C7C7C] p-[1.5rem] lg:p-[2.5rem] text-left font-bold">
            Creative Content Generation
          </h1>
          {isExpanded ? (
            <motion.div 
            animate={{ rotate: isExpanded ? 90 : 0 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            >
              <FiArrowUpRight className="font-extrabold text-2xl lg:text-3xl mx-[1rem] lg:mx-[3rem] text-[#1F7EA1] " />
            </motion.div>
          ) : (
            <FiArrowUpRight className="font-extrabold text-2xl lg:text-3xl mx-[1rem] lg:mx-[3rem] text-[#7C7C7C]" />
          )}
        </div>
        {isExpanded && (
          <motion.div
            className=""
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: isExpanded ? "auto" : 0 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
          >
            <p className="text-[#7C7C7C] mx-[1.5rem] lg:mx-[2.5rem] mb-[1.5rem] lg:mb-[2.5rem]">
              For a top-notch brand, visuals are everything. Whether we're
              diving into logo design, crafting eye-popping social media posts,
              revamping your UI, producing game-changing video ads, photoshoots,
              or even handling web and app development, count on us. We're all
              about those visual vibes that make your brand pop.
            </p>
            <div className="flex md:flex-row flex-wrap md:justify-start justify-center overflow-hidden">
              <NavLink
                to="/creative#graphicdesign"
                className="lg:ml-[2.5rem] text-white"
              >
                <button className="tailwind-style bg-[#9746FE] text-sm md:text-md p-2 px-3 rounded-full mb-[1.5rem] lg:mb-[2.5rem]">
                  Graphic Design<span className="shine"></span>
                </button>
              </NavLink>
              <NavLink
                className="ml-[1.5rem] text-white  "
                to="/creative#ugcads"
              >
                <button className="tailwind-style bg-[#9746FE] text-sm md:text-md p-2 px-3 rounded-full mb-[1.5rem] lg:mb-[2.5rem]">
                  Shoots and UGC Ads<span className="shine"></span>
                </button>
              </NavLink>
              <NavLink
                className="lg:ml-[1.5rem] text-white  "
                to="/creative#webappdevelopment"
              >
                <button className="tailwind-style bg-[#9746FE] text-sm md:text-md p-2 px-3 rounded-full mb-[1.5rem] lg:mb-[2.5rem]">
                  Web & App development<span className="shine"></span>
                </button>
              </NavLink>
            </div>
          </motion.div>
        )}
      </div>
      <div
        className={`border-2 max-w-5xl border-[#7C7C7C] my-5 lg:my-10 mx-5 lg:mx-auto ${
          isExpanded2 ? "rounded-[2rem]" : "rounded-[5rem] cursor-pointer"
        }`}
      >
        <div
          className="flex justify-between items-center"
          onClick={handleExpand2}
        >
          <h1 className="z-[40] text-sm lg:text-2xl text-[#7C7C7C] p-[1.5rem] lg:p-[2.5rem] text-left font-bold">
            AI & Automation
          </h1>
          {isExpanded2 ? (
            <motion.div 
            animate={{ rotate: isExpanded2 ? 90 : 0 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            >
              <FiArrowUpRight className="font-extrabold text-2xl lg:text-3xl mx-[1rem] lg:mx-[3rem] text-[#1F7EA1]" />
            </motion.div>
          ) : (
            <FiArrowUpRight className="font-extrabold text-2xl lg:text-3xl mx-[1rem] lg:mx-[3rem] text-[#7C7C7C]" />
          )}
        </div>
        {isExpanded2 && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: isExpanded2 ? "auto" : 0 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
          >
            <p className="text-[#7C7C7C] mx-[1.5rem] lg:mx-[2.5rem] mb-[1.5rem] lg:mb-[2.5rem]">
              We're not just about AI; we're about Automation with purpose.
              We're here to supercharge your decision-making, whether it's
              nailing medical diagnoses, picking your dream ride, or
              streamlining those workflows. AI's not just a tool; it's your
              trusty sidekick.
            </p>
            <div className="flex md:flex-row flex-wrap md:justify-start justify-center overflow-hidden">
              <NavLink
                className="lg:ml-[2.5rem] text-white"
                to="/automate#workflowautomation"
              >
                <button className="tailwind-style bg-[#9746FE] text-sm md:text-md p-2 px-3 rounded-full mb-[1.5rem] lg:mb-[2.5rem]">
                  Workflow Automation<span className="shine"></span>
                </button>
              </NavLink>
              <NavLink
                className="ml-[1.5rem] text-white"
                to="/automate#aiinmarketing"
              >
                <button className="tailwind-style bg-[#9746FE] text-sm md:text-md p-2 px-3 rounded-full mb-[1.5rem] lg:mb-[2.5rem]">
                  AI in Marketing<span className="shine"></span>
                </button>
              </NavLink>
              <NavLink
                className="lg:ml-[1.5rem] text-white  "
                to="/automate#aichatbots"
              >
                <button className="tailwind-style bg-[#9746FE] text-sm md:text-md p-2 px-3 rounded-full mb-[1.5rem] lg:mb-[2.5rem]">
                  AI Chatbots & Assistants<span className="shine"></span>
                </button>
              </NavLink>
            </div>
          </motion.div>
        )}
      </div>
      <div
        className={`border-2 max-w-5xl border-[#7C7C7C] my-5 lg:my-10 mx-5 lg:mx-auto ${
          isExpanded3 ? "rounded-[2rem]" : "rounded-[5rem] cursor-pointer"
        }`}
      >
        <div
          className="flex justify-between items-center"
          onClick={handleExpand3}
        >
          <h1 className="z-[40] text-sm lg:text-2xl text-[#7C7C7C] p-[1.5rem] lg:p-[2.5rem] text-left font-bold">
            Marketing
          </h1>
          {isExpanded3 ? (
            <motion.div 
            animate={{ rotate: isExpanded2 ? 90 : 0 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            >
              <FiArrowUpRight className="text-2xl font-extrabold lg:text-3xl mx-[1rem] lg:mx-[3rem] text-[#1F7EA1] transition-transform ease-in-out duration-300"/>
            </motion.div>
          ) : (
            <FiArrowUpRight className="font-extrabold text-2xl lg:text-3xl mx-[1rem] lg:mx-[3rem] text-[#7C7C7C]" />
          )}
        </div>
        {isExpanded3 && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: isExpanded3 ? "auto" : 0 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
          >
            <p className="text-[#7C7C7C] mx-[1.5rem] lg:mx-[2.5rem] mb-[1.5rem] lg:mb-[2.5rem]">
              Marketing isn't just numbers; it's 80% perception, 20% plans. But
              here's the kicker: most agencies overlook both, mindlessly tossing
              ads on the wrong platforms to the wrong crowd. Not us. We're on a
              different wavelength. We craft your brand strategy with
              cutting-edge practices like neuromarketing, boost your organic
              discoverability, and invest smartly in channels that bring real
              results.
            </p>
            <div className="flex md:flex-row flex-wrap md:justify-start justify-center overflow-hidden">
              <NavLink
                className="lg:ml-[2.5rem] text-white  "
                to="marketing#brand"
              >
                <button className="tailwind-style bg-[#9746FE] text-sm md:text-md p-2 px-3 rounded-full mb-[1.5rem] lg:mb-[2.5rem]">
                  Brand Strategy<span className="shine"></span>
                </button>
              </NavLink>
              <NavLink
                className="ml-[1.5rem] text-white  "
                to="marketing#organicdiscoverability"
              >
                <button className="tailwind-style bg-[#9746FE] text-sm md:text-md p-2 px-3 rounded-full mb-[1.5rem] lg:mb-[2.5rem]">
                  Organic Discoverability<span className="shine"></span>
                </button>
              </NavLink>
              <NavLink
                className="lg:ml-[1.5rem] text-white  "
                to="marketing#adsmedia"
              >
                <button className="tailwind-style bg-[#9746FE] text-sm md:text-md p-2 px-3 rounded-full mb-[1.5rem] lg:mb-[2.5rem]">
                  Advertising & Media Allocations<span className="shine"></span>
                </button>
              </NavLink>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Services;
