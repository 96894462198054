import { useState } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { NavLink, useNavigate} from "react-router-dom";

function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const goToContact = () => {
    // window.scrollTo(0, document.body.scrollHeight);
    document.getElementById("contact-form").scrollIntoView();
  };

  const toggleDrop = () => {
    setToggleDropDown(!toggleDropDown);
  };

  const [isMarketingDropdownOpen, setMarketingDropdownOpen] = useState(false);

  const toggleMarketingDropdown = () => {
    setMarketingDropdownOpen(!isMarketingDropdownOpen);
  };
  return (
    <>
    {/* bg-opacity-30 backdrop-blur-sm bg-[#202427]*/}
      <div className={`max-w-[100vw] px-[2.5vw] py-[1.5vh] z-50 fixed w-full top-0 ${toggleMenu ? 'bg-opacity-30 backdrop-blur-sm' : 'bg-transparent'}`}>
        <nav className="max-w-7xl mx-auto">
          <div
            className={`justify-around my-0  ${
              !toggleMenu
                ? " "
                : "bg-[#FF3365] bg-opacity-0 backdrop-blur-0 duration-700"
            }`}
          >
            <div className="grid grid-cols-12">
              <div className="mr-auto my-auto col-span-2">
                <NavLink to="/"> 
                <img
                  src="../assets/logo.webp"
                  alt="Logo"
                  className="w-auto h-[55px]"
                />
                </NavLink>
                {/* <h1 className="text-white text-xl lg:text-5xl font-medium">LOGO</h1> */}
              </div>
              <div className="hidden lg:inline-flex col-span-10 ">
                <div className="scale-90 lg:scale-100 col-span-10 m-auto grid grid-cols-4 bg-white rounded-full gap-2 text-sm font-bold justify-between shadow-md">
                  <NavLink
                    to="/"
                    className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-white text-center"
                    style={({ isActive, isPending }) => {
                      return {
                        color: isActive ? "white" : "#202427",
                        backgroundColor: isActive ? "#202427" : "white",
                      };
                    }}
                  >
                    HOME
                  </NavLink>
                  <NavLink
                    to="/creative"
                    className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-white text-center"
                    style={({ isActive, isPending }) => {
                      return {
                        color: isActive ? "white" : "#202427",
                        backgroundColor: isActive ? "#202427" : "white",
                      };
                    }}
                  >
                    CREATIVE
                  </NavLink>
                  <NavLink
                    to="/automate"
                    className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-white text-center"
                    style={({ isActive, isPending }) => {
                      return {
                        color: isActive ? "white" : "#202427",
                        backgroundColor: isActive ? "#202427" : "white",
                      };
                    }}
                  >
                    AUTOMATE
                  </NavLink>
                  <div className="relative py-[2.1vh]">
                    <NavLink
                      to="/marketing"
                      className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] mr-[2rem] hover:text-white text-center"
                      style={({ isActive, isPending }) => {
                        return {
                          color: isActive ? "white" : "#202427",
                          backgroundColor: isActive ? "#202427" : "white",
                        };
                      }}
                    >
                      MARKETING
                    </NavLink>
                    <div className="absolute top-1/2 -right-0 transform -translate-y-1/2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        className="w-5 h-5 mr-1.5 text-black"
                        onClick={handleDropdownClick}
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    {isDropdownOpen && (
                      <div className="absolute top-full right-0 mt-1 bg-white rounded-lg shadow-lg">
                        <NavLink
                          to="/outsource"
                          className="block px-4 py-2 text-sm text-[#1D1F21] m-1 rounded-full"
                          style={({ isActive, isPending }) => {
                            return {
                              color: isActive ? "white" : "#202427",
                              backgroundColor: isActive ? "#202427" : "white",
                            };
                          }}
                        >
                          OUTSOURCE
                        </NavLink>
                        <NavLink
                          to="/blog"
                          className="block px-4 py-2 text-sm text-[#1D1F21] m-1 rounded-full"
                          style={({ isActive, isPending }) => {
                            return {
                              color: isActive ? "white" : "#202427",
                              backgroundColor: isActive ? "#202427" : "white",
                            };
                          }}
                        >
                          BLOG
                        </NavLink>
                      </div>
                    )}
                  </div>
                </div>
                <div className="scale-90 lg:scale-100 col-span-2 ml-auto text-sm font-bold my-auto">
                  <div className="w-full hover:bg-white border-2 border-white rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-[#202427] text-black text-center  bg-gray-300 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-50">
                    <NavLink to="/getintouch">GET IN TOUCH</NavLink>
                  </div>
                </div>
              </div>
              {/* Mobile navigation toggle */}
              <div className="col-span-10 col-start-13 lg:hidden flex text-white">
                <button onClick={() => setToggleMenu(!toggleMenu)}>
                  <Bars3Icon className="lg:h-8 h-6" />
                </button>
              </div>
            </div>
          </div>
          {/* mobile navigation */}
          <div
            className={`z-40 w-full overflow-hidden flex flex-col lg:hidden gap-8 origin-top duration-700 ${
              !toggleMenu ? "h-0" : "h-screen bg-fixed"
            }`}
          >
            <div className="px-8 py-[2.5vh]">
              <div className="flex flex-col font-light gap-8 text-2xl tracking-wider text-center">
                <div className="p-[15%] row-span-8 m-auto grid grid-rows-4 bg-white rounded-3xl gap-2 font-bold justify-between">
                  <NavLink
                    to="/"
                    className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-white text-center"
                    style={({ isActive, isPending }) => {
                      return {
                        color: isActive ? "white" : "#202427",
                        backgroundColor: isActive ? "#202427" : "white",
                      };
                    }}
                  >
                    HOME
                  </NavLink>
                  <NavLink
                    to="/creative"
                    className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-white text-center"
                    style={({ isActive, isPending }) => {
                      return {
                        color: isActive ? "white" : "#202427",
                        backgroundColor: isActive ? "#202427" : "white",
                      };
                    }}
                  >
                    CREATIVE
                  </NavLink>
                  <NavLink
                    to="/automate"
                    className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-white text-center"
                    style={({ isActive, isPending }) => {
                      return {
                        color: isActive ? "white" : "#202427",
                        backgroundColor: isActive ? "#202427" : "white",
                      };
                    }}
                  >
                    AUTOMATE
                  </NavLink>
                  <NavLink
                      to="/marketing"
                      className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-white text-center"
                      style={({ isActive, isPending }) => {
                        return {
                          color: isActive ? "white" : "#202427",
                          backgroundColor: isActive ? "#202427" : "white",
                        };
                      }}
                  >
                    MARKETING
                  </NavLink>
                  <NavLink
                      to="/outsource"
                      className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-white text-center"
                      style={({ isActive, isPending }) => {
                        return {
                          color: isActive ? "white" : "#202427",
                          backgroundColor: isActive ? "#202427" : "white",
                        };
                      }}
                  >
                    OUTSOURCE
                  </NavLink>
                  <NavLink
                      to="/blog"
                      className="hover:bg-[#202427] rounded-full py-[1.5vh] px-[1.5vw] m-[2.5%] hover:text-white text-center"
                      style={({ isActive, isPending }) => {
                        return {
                          color: isActive ? "white" : "#202427",
                          backgroundColor: isActive ? "#202427" : "white",
                        };
                      }}
                  >
                    BLOG
                  </NavLink>
                </div>
                <div className="row-span-2 mx-auto font-bold my-auto">
                  <div className="w-full hover:bg-white border-2 border-white rounded-full py-[1.5vh] px-[5vw] m-[2.5%] hover:text-[#202427] text-white text-center">
                    <NavLink to="/getintouch">GET IN TOUCH</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
