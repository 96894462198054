import React from "react";
import { brandData } from "./data";
import { discoverabilityData } from "./data";
import { mediaData } from "./data";
import { motion } from "framer-motion";
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import { useInView } from "react-intersection-observer";

const ContentCard = () => {
  return (
    <div>
      {brandData.map((item, index) => (
        <div key={index} className="grid grid-rows-2 md:flex items-center justify-center my-12">
          <motion.div 
          // variants={fadeIn("up", "spring", 0.8, 0.75)}
          >
            <h1></h1>
            <img
              src={item.image}
              alt="Brand Strategy"
              className="md:w-[40rem] md:h-[40rem] mx-auto"
            />
          </motion.div>
          <motion.div
            className="w-full md:w-1/3 md:px-0 px-[2.5vw] text-center md:text-left"
            // variants={fadeIn("up", "spring", 1.4, 0.75)}
          >
            <div className="inline-block">
              <h1 className="font-bold pb-2 text-[1.5rem]">{item.tagline}</h1>
              <hr className="w-full" />
            </div>
            <p className="mt-8 font-thin text-[1.3rem]">{item.description}</p>
          </motion.div>
        </div>
      ))}
    </div>
  );
};



const ContentCard2 = () => {
    return (
        <div>
            {discoverabilityData.map((item, index) => (
                <div key={index} className="grid grid-rows-2 md:flex items-center justify-center mb-[15rem] px-[2.5vw] md:px-0">
                    <motion.div 
                        className="mx-[3rem]"
                        // variants={fadeIn("up", "spring", 1.4*index, 0.5)}
                    >
                        <h1></h1>
                        <img 
                            src={item.image} 
                            alt="Brand Strategy" 
                            className="w-full h-full md:w-[30rem] md:h-[30rem] mx-auto rounded-[70px]"
                        />
                    </motion.div>
                    <motion.div 
                        className="md:w-[40%] md:mx-[4rem] px-[2.5vw] md:px-0 text-center md:text-left"
                        // variants={fadeIn("up", "spring", 1.8*index, 1.0)} 
                    >
                        <div className="inline-block">
                            <h1 className="font-black text-6xl mb-7">{item.title1} <span style={{color: item.color}}>{item.title2}</span></h1>
                            <h1 className="font-bold pb-2 text-[1.3rem]">{item.tagline}</h1>
                            <hr className="w-full" style={{ borderColor: "#1D1F21" }}/>
                        </div>
                        <p className="mt-8 font-thin text-[1.2rem]">{item.description}</p>
                    </motion.div>
                </div>
            ))}
        </div> 
    )
}
  

const ContentCard3 = () => {
  return (
    <div>
      {mediaData.map((item, index) => (
        <div key={index} className="grid grid-rows-2 md:flex items-center justify-center my-[9rem] text-center md:text-left">
          <motion.div 
            className="mx-[3rem]"
            // variants={fadeIn("up", "spring", 1.4*index, 0.5)}
          >
            <h1></h1>
            <img
              src={item.image}
              alt="Brand Strategy"
              className="md:w-[30rem] md:h-[30rem] mx-auto rounded-[70px]"
            />
          </motion.div>
          <motion.div 
            className="md:w-[37%] md:mx-[4rem] px-[2.5vw] md:px-0"
            // variants={fadeIn("up", "spring", 1.8*index, 1.0)} 
          >
            <div className="inline-block">
              <h1 className="font-black text-6xl mb-7">
                {item.title1}{" "}
                <span style={{ color: item.color }}>{item.title2}</span>
              </h1>
              <h1 className="font-bold pb-2 text-[1.3rem]">{item.tagline}</h1>
              <hr className="w-full" style={{ borderColor: "#F7F4EC" }} />
            </div>
            <p className="mt-8 font-thin text-[1.2rem]">{item.description}</p>
          </motion.div>
        </div>
      ))}
    </div>
  );
};

export { ContentCard, ContentCard2, ContentCard3 };
