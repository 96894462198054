import React, {useEffect, useState} from "react";
import SyncLoader from "react-spinners/SyncLoader";
import loadinglogo from "../assets/loading.webp";

const Error = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    return (
        <div>
            {loading? <div className="h-screen w-screen bg-black flex flex-col justify-center items-center">
                <img src={loadinglogo} alt="loading"/>
                <SyncLoader
                    size={20}
                    color={"#fff"}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div> :
            <h1>Sorry, You are Lost!</h1>
            }
        </div>
    )
}

export default Error;