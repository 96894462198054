import React, { useState, useEffect } from "react";
import Navbar from "../Home/navbar";
import "./index.css";
import Typewriter from "react-typewriter-effect";
import marketingline from "../../assets/marketinglineelement.svg";
import { SectionWrapper } from "../../hoc";
import {motion} from "framer-motion";
import { fadeIn, textVariant } from "../../utils/motion";

const Introduction = () => {
    return ( 
        <div className="h-full md:h-[100vh]">
            <div className="lg:h-[100vh] h-auto absolute top-0 -z-30">
                <video loop autoPlay={true} className="w-auto object-cover h-[20rem] md:h-auto md:w-full">
                    <source src="/assets/marketting.mp4" type="video/mp4"/>
                </video>
            </div>
            <div className="pt-16 md:pt-0 h-[20rem] lg:h-[100vh] bg-[#1D1F21] bg-opacity-50">
                <div className="max-w-7xl mx-auto lg:py-[25vh] py-6 relative top-0">
                    <div className="grid grid-cols-12 px-[2.5vw] text-[#F7F4EC] lg:gap-24">
                        <motion.h1 
                            className="col-start-2 lg:col-start-2 text-4xl lg:text-[7rem] font-[800] col-span-full"
                            variants={fadeIn("up", "tween", 0.5, 0.5)}
                        >
                            Let's make your
                        </motion.h1>
                        <motion.h1 
                            className="col-start-4 lg:col-start-4 text-4xl lg:text-[7rem] font-[800] col-span-full"
                            variants={fadeIn("up", "tween", 1, 0.5)}    
                        >
                            <div className="flex items-center">
                                <div className="words text-[#9747FF] text-center">
                                    <span className="anime block py-[2rem] text-transparent">Product</span>
                                    <span className="anime block py-[2rem]">Product</span>
                                    <span className="anime block py-[2rem]">Service</span>
                                    <span className="anime block py-[2rem]">Idea</span>
                                    <span className="anime block py-[2rem]">Brand</span>
                                </div> 
                                <div>
                                    <h1 className="px-3">visible</h1>
                                </div>
                            </div>
                        </motion.h1>
                    </div>
                    <motion.div 
                        className="md:my-[3rem] mx-auto px-32 md:px-0"
                        variants={fadeIn("up", "tween", 1.5, 0.5)}
                    >
                        <img src="../assets/marketinglineelement.svg" className="mx-auto"/>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default SectionWrapper(Introduction);

// <div className="relative">
//             <video
//                 autoPlay
//                 loop
//                 muted
//                 className="w-full h-full object-cover"
//             >
//                 <source src="assets/marketting.mp4" type="video/mp4"/>
//             </video>
//             <div className="absolute top-0 left-0 w-full h-full bg-multiple-gradients">
//                 <div className="text-center mt-1/2" style={{ position: 'absolute', top: '25%', transform: 'translateY(-50%)', left: '50%', transform: 'translateX(-50%)' }}>
//                     <h1 className="flex justify-center items-center font-extrabold text-8xl text-white mr-[15rem]">Let's make your</h1>
//                     <h1 className="flex justify-center items-center font-extrabold text-8xl text-white ml-[19rem]"><span className="text-[#9747FF] px-5">Product</span> visible</h1>
//                     <div className="flex justify-center items-center">
//                         <img src="assets/marketinglineelement.svg" />
//                     </div>
//                 </div>
//             </div>
//         </div>