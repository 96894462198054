import React, {useEffect, useState} from "react";
import Navbar from "../components/Home/navbar";
import Introduction from "../components/Marketing/introduction";
import Process from "../components/Marketing/process";
import BrandStrategy from "../components/Marketing/brandStrategy";
import Discoverability from "../components/Marketing/discoverability";
import Media from "../components/Marketing/media";
import Footer from "../components/Home/footer";
import loadinglogo from "../assets/loading.webp";
import SyncLoader from "react-spinners/SyncLoader";

const Marketing = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    return (
        <div>
            {loading ?
                <div className="h-screen w-screen flex bg-black flex-col justify-center items-center">
                <img src={loadinglogo} alt="loading"/>
                <SyncLoader
                    size={20}
                    color={"#fff"}
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div> :
          <div>
              <Navbar/>
              <Introduction/>
              <Process/>
              <BrandStrategy/>
              <Discoverability/>
              <Media/>
              <Footer/>
          </div>
            }
        </div>
    )
}

export default Marketing;