import React from "react";
import { MarketingData } from "./data";
import {motion} from 'framer-motion';
import { SectionWrapper } from "../../hoc";
import { fadeIn, textVariant } from "../../utils/motion";
import Process from "./process";

const ProcessCard = ({index}) => {
    return (
        <div className="flex text-white justify-start md:justify-center max-w-screen-2xl overflow-scroll">
            {MarketingData.map((item, index) => (
                <motion.div 
                    key={index} 
                    variants={fadeIn("right", "spring", 0.5*index, 0.75)}
                    style={{backgroundColor: item.color}} 
                    className="m-[0.5rem] h-[14rem] p-[1rem] w-[11rem] rounded-xl shadow-xl"
                >
                    <img 
                        src={item.icon}
                        alt="item icon"
                        className="w-6 h-6 my-3" 
                    />
                    <span className="font-semibold">{item.step}</span>
                    <h1 className="text-2xl font-black pt-6">{item.title}</h1>
                </motion.div>
            ))}
        </div>
    )
}

export default SectionWrapper(ProcessCard, "processcard");