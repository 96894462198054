import React from "react";
import ProcessCard from "./processcard";

const Process = () => {
    return (
        <div className="h-full md:h-[100vh] bg-[#F7F4EC] w-screen mb-8 md:mb-0">
            <h1 className="text-7xl flex justify-center font-black p-[2rem] pt-[5rem]">PRO<span className="text-[#F25878]">CESS</span></h1>
            <img src="/assets/lineElement.svg" className="mx-auto pb-11"/>
            <ProcessCard/>
        </div>
    )
}

export default Process;